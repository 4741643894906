<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<div class="performance__content">
				<h2 class="performance__title">Total <br> assets</h2>
				<small class="performance__subtitle primary-base">in Millions</small>
			</div>
			<div class="performance__graphic">
				<number-block class="number-block--yellow" :numbers="totalAssets" />
			</div>
		</div>
	</div>
</template>

<script>
import NumberBlock from '@/components/NumberBlock'

export default {
	name: 'TotalAssets',
	components: {
		NumberBlock,
	},
	data: () => ({
		totalAssets: [
			{
				value: 74168,
				title: '2020',
				options: {
					prefix: '$',
				},
			},
			{
				value: 52137,
				title: '2019',
				options: {
					prefix: '$',
				},
			},
			{
				value: 47891,
				title: '2018',
				options: {
					prefix: '$',
				},
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
